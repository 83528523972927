import React from 'react';
import { Box, StyledHR } from '@rsos/components/capstone/base';
import Dropdown from '@rsos/components/capstone/base/Dropdown';
import {
  DropdownDialog,
  DropdownBody,
  DropdownList,
  DropdownListItem,
  DropdownFooter,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import { CENTRAL_STATION } from '@rsos/constants/adminContents';
import determineDataPath from '@rsos/utils/determineDataPath';
import Protected from '../../Protected';
import VersionNumber from '../../VersionNumber';
import StyledAuthLink from '../common/StyledAuthLink';
import StyledSmartLink from '../common/StyledSmartLink';
import { HamburgerMenu } from '../common/common.styles.js';
import { mapAdminPermissionsToPath } from '../common/helpers';

const NavigationControl = ({ user, handleLogout, irpAccessToken }) => {
  const { pageAccessList } = user;
  const displayAdminMenuItem = mapAdminPermissionsToPath(pageAccessList);
  const isLoggedIn = user?.isLoggedIn;

  return (
    <Box data-name="navigation-menu" position="relative">
      <Dropdown triggerElement={HamburgerMenu}>
        <DropdownDialog top={43} right={6} width="200px">
          <DropdownBody>
            <Protected isLoggedIn={user.isLoggedIn}>
              <DropdownList borderless>
                <StyledSmartLink
                  data-name="navigation-control-emergency-data-link"
                  to={determineDataPath(
                    CENTRAL_STATION,
                    false,
                    irpAccessToken,
                    isLoggedIn,
                  )}
                >
                  <DropdownListItem>Data</DropdownListItem>
                </StyledSmartLink>
                {displayAdminMenuItem && (
                  <StyledSmartLink
                    to={displayAdminMenuItem}
                    data-name="navigation-control-admin-link"
                  >
                    <DropdownListItem>Admin</DropdownListItem>
                  </StyledSmartLink>
                )}

                <Box py={10} px={16}>
                  <VersionNumber />
                </Box>
              </DropdownList>
            </Protected>
          </DropdownBody>
          <StyledHR
            borderTop="1px solid"
            borderBottom="0px"
            borderColor="lightLineDivider"
            m={0}
          />
          <DropdownFooter>
            <Box p={10}>
              <StyledAuthLink onClick={handleLogout} />
            </Box>
          </DropdownFooter>
        </DropdownDialog>
      </Dropdown>
    </Box>
  );
};

export default NavigationControl;
