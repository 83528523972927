/** Default fallback for org settings in CSP */

export default {
  audio_settings: {
    alerts: {
      sound_on: false,
      tone: 'glassVibes',
    },
  },
};
