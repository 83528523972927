import React from 'react';
import {
  DropdownList,
  DropdownListItem,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import VolumeSlider from '../VolumeSlider';
import { VolumeSliderBlock } from './SoundPlayer.styles.js';

const SoundPlayer = ({ orgAudioSettings }) => {
  return (
    <DropdownList borderless padding="5px 0px">
      {Object.keys(orgAudioSettings).map(
        setting =>
          orgAudioSettings[setting].sound_on && (
            <DropdownListItem key={setting} noLiHover>
              <VolumeSliderBlock>
                <VolumeSlider type={setting} min={0} max={1} />
              </VolumeSliderBlock>
            </DropdownListItem>
          ),
      )}
    </DropdownList>
  );
};

export default React.memo(SoundPlayer);
