import React, { useState } from 'react';
import { AudioControlIcon } from '@rsos/assets/icons';
import { Box, Button } from '@rsos/components/capstone/base';
import { DisabledAudioTooltip } from './AudioDisabled.styles';

const AudioDisabled = () => {
  const [isVisible, setIsVisible] = useState(false);
  const onMouseEnter = () => {
    setIsVisible(true);
  };
  const onMouseLeave = () => {
    setIsVisible(false);
  };
  return (
    <Box horizontal>
      <Box
        position="relative"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Button
          btnType="tertiary"
          size="small"
          data-name="audio-control-sound-button"
          disabled={true}
        >
          <AudioControlIcon color="secondaryText" />
        </Button>
        {isVisible && <DisabledAudioTooltip />}
      </Box>
    </Box>
  );
};

export default AudioDisabled;
