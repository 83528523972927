import { gspx } from '@rsos/capstone';

export const trackTestModeToggle = (isOn, irpVersion) => {
  const testModeStatus = isOn ? 'on' : 'off';

  gspx.trackCustomEvent('Alert', {
    irpVersion,
    name: `Click turn ${testModeStatus} test mode in CSP`,
    Category: 'Alert',
    'Launched date': new Date(),
  });
};
