import React from 'react';
import { useSelector } from 'react-redux';
import { AudioControlIcon, AudioMuteIcon } from '@rsos/assets/icons';
import { Box, Button, Dropdown } from '@rsos/base-components';
import {
  DropdownDialog,
  DropdownBody,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import { cspAudioOrgSettingsSelector } from '@rsos/reduxDataStore/src/features/selectors/cspOrgSettingsSelectors';
import { cspAudioUserSettingsSelector } from '@rsos/reduxDataStore/src/features/selectors/cspUserSettingsSelectors';
import AudioDisabled from './AudioDisabled';
import SoundPlayer from './SoundPlayer';

const VolumeControl = () => {
  const orgAudioSettings = useSelector(state =>
    cspAudioOrgSettingsSelector(state.sinatra),
  );
  const userAudioSettings = useSelector(state =>
    cspAudioUserSettingsSelector(state.sinatra),
  );
  /** If on the admin page the sound toggle is disabled, we display disabled volume control
   * with a tooltip
   */
  const isSoundOn = Object.values(orgAudioSettings).some(o => o?.sound_on);

  /** If the volume muted while the admin page sound toogle is enabled, we display the volume control
   * with an indicator that it is muted
   */
  const isVolumeOn = Object.keys(userAudioSettings).some(
    o => userAudioSettings[o].volume > 0 && orgAudioSettings[o]?.sound_on,
  );
  return (
    <Box horizontal>
      {isSoundOn ? (
        <Box data-name="csp-navigation-audio" position="relative">
          <Dropdown
            triggerElement={
              isVolumeOn
                ? AudioControlButtonVolumeOn
                : AudioControlButtonVolumeOff
            }
          >
            <DropdownDialog top={43} right={2} width="278px">
              <DropdownBody>
                <SoundPlayer orgAudioSettings={orgAudioSettings} />
              </DropdownBody>
            </DropdownDialog>
          </Dropdown>
        </Box>
      ) : (
        <AudioDisabled />
      )}
    </Box>
  );
};

export default VolumeControl;

const AudioControlButtonVolumeOn = ({ selected, onClick }) => {
  return (
    <Button
      btnType="tertiary"
      size="small"
      dataName="csp-audio-control-sound-button"
      onClick={onClick}
      selected={selected}
    >
      <AudioControlIcon color="secondaryText" />
    </Button>
  );
};
const AudioControlButtonVolumeOff = ({ selected, onClick }) => {
  return (
    <Button
      btnType="tertiary"
      size="small"
      dataName="csp-audio-control-sound-button"
      onClick={onClick}
      selected={selected}
    >
      <AudioMuteIcon color="secondaryText" />
    </Button>
  );
};
