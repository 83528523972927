import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';

export const VolumeSliderBlock = styled(Box).attrs({
  bg: 'foreground',
  horizontal: true,
  height: 45,
  width: '100%',
  justify: 'space-between',
  align: 'center',
})``;
