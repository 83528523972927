import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetIDWithSound } from '@rsos/reduxDataStore/src/features/alerts/incidentsSlice';
import { filteredAlertsSelector } from '@rsos/reduxDataStore/src/features/selectors/alertsSelectors';
import { cspAudioOrgSettingsSelector } from '@rsos/reduxDataStore/src/features/selectors/cspOrgSettingsSelectors';
import { cspAudioUserSettingsSelector } from '@rsos/reduxDataStore/src/features/selectors/cspUserSettingsSelectors';
import usePrevious from '@rsos/utils/usePrevious';
import {
  getSoundFilePath,
  handleAudioPlayback,
} from '../../RSP/SettingsControl/Settings/helpers';

const useSoundNotification = () => {
  const dispatch = useDispatch();

  // General selectors
  const incidents = useSelector(state => state.incidents);

  const orgAudioSettings = useSelector(state =>
    cspAudioOrgSettingsSelector(state.sinatra),
  );

  const userAudioSettings = useSelector(state =>
    cspAudioUserSettingsSelector(state.sinatra),
  );

  // Alert Information
  const alerts = filteredAlertsSelector(incidents);
  const { IDWithSound, selectedAlertID } = incidents;

  // volume variables
  const hasSoundAndVolume =
    orgAudioSettings?.alerts?.sound_on && userAudioSettings?.alerts?.volume;

  const volumeAlertsValue = hasSoundAndVolume
    ? userAudioSettings.alerts.volume
    : 0;

  // alert specific variables
  const previousIDWithSound = usePrevious(IDWithSound);

  // alert specific useEffect hooks
  useEffect(() => {
    if (orgAudioSettings) {
      // When a new alert comes in, it plays a single sound
      // CSP doesn't currently support repeatable sounds
      const alertsTone = orgAudioSettings.alerts.tone;
      let selectedAudio = getSoundFilePath(alertsTone);
      selectedAudio.volume = volumeAlertsValue;
      const alert = alerts[IDWithSound];

      if (
        volumeAlertsValue > 0 &&
        IDWithSound &&
        alert?.location_history?.length === 1 &&
        IDWithSound !== previousIDWithSound
      ) {
        handleAudioPlayback(selectedAudio, alertsTone);

        if (IDWithSound && selectedAlertID && IDWithSound !== selectedAlertID) {
          // Reset `IDWithSound` after sound is played for new alerts that don't
          // match the `selectedAlertID`
          dispatch(resetIDWithSound());
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IDWithSound]);
};

export default useSoundNotification;
