import React from 'react';
import styled from 'styled-components';
import { Box, Absolute } from '@rsos/components/capstone/base';

export const DisabledAudioTooltip = () => {
  return (
    <Absolute bottom="-32px" left="-50px">
      <StyledTooltip>Your sound has been disabled</StyledTooltip>
    </Absolute>
  );
};

export const StyledTooltip = styled(Box).attrs(() => ({
  width: 197,
  padding: '5px',
  height: 34,
  backgroundColor: 'primaryText',
  color: 'background',
  justify: 'center',
  align: 'center',
  borderRadius: 5,
}))`
  font-size: 12px;
`;
