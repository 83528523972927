import { createSelector } from '@reduxjs/toolkit';
import { CENTRAL_STATION } from '@rsos/constants/adminContents';
import { CSP_ORG_SETTINGS_DEFAULT } from '@rsos/fixtures';

const resolveOrgSettings = user => {
  const { id: orgID } = user.currentOrg;
  const foundOrg = user.profile?.organizations?.find(o => o.id === orgID);
  if (foundOrg && foundOrg.attributes && foundOrg.attributes[CENTRAL_STATION]) {
    return foundOrg.attributes[CENTRAL_STATION];
  } else {
    return CSP_ORG_SETTINGS_DEFAULT;
  }
};

/**
 * selector to return CSP org settings or the default value
 */

export const cspOrgSettingsSelector = createSelector(
  sinatra => sinatra,
  sinatra => {
    return resolveOrgSettings(sinatra.user);
  },
);

const resolveAudioOrgSettings = user => {
  const { id: orgID } = user.currentOrg;
  const foundOrg = user.profile?.organizations?.find(o => o.id === orgID);
  if (
    foundOrg &&
    foundOrg.attributes &&
    foundOrg.attributes[CENTRAL_STATION] &&
    foundOrg.attributes[CENTRAL_STATION].audio_settings
  ) {
    return foundOrg.attributes[CENTRAL_STATION].audio_settings;
  } else {
    return CSP_ORG_SETTINGS_DEFAULT.audio_settings;
  }
};

/**
 * selector to return CSP audio org settings or the default value
 */

export const cspAudioOrgSettingsSelector = createSelector(
  sinatra => sinatra,
  sinatra => {
    return resolveAudioOrgSettings(sinatra.user);
  },
);
