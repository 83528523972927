import styled, { keyframes } from 'styled-components';
import { Box, Text } from '@rsos/components/capstone/base';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';

const fadeIn = keyframes`
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
`;
export const Container = styled(Box).attrs({
  px: 20,
  color: 'background',
  align: 'center',
  bg: 'primaryBase',
  horizontal: true,
  flow: 10,
  height: 43,
  borderRadius: 5,
})`
  animation: ${fadeIn} 0.3s linear;
  width: calc(100% - 450px);
  @media (max-width: ${BP_LAPTOP}px) {
    width: calc(100% - 300px);
  }
`;

export const TextWrapper = styled(Text)`
  text-overflow: ellipsis;
  overflow: auto;
  white-space: nowrap;
`;
