import { gspx } from '@rsos/capstone';

export const trackClickMuteAudioControl = (irpVersion, type) => {
  gspx.trackCustomEvent('Alert', {
    irpVersion,
    name: `Mute Sound Control for ${type} was clicked in CSP`,
    Category: 'Alert',
    'Launched date': new Date(),
  });
};

export const trackChangeAudioVolume = (irpVersion, type) => {
  gspx.trackCustomEvent('Alert', {
    irpVersion,
    name: `The volume for ${type} Audio Notifications was changed in CSP`,
    Category: 'Alert',
    'Launched date': new Date(),
  });
};
