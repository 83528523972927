import React from 'react';
import { AttentionTriangleIcon } from '@rsos/assets/icons';
import { Container, TextWrapper } from './TestModeBanner.styles';

const TestModeBanner = () => {
  return (
    <Container>
      <AttentionTriangleIcon height={14} width={14} />
      <TextWrapper py={20} size="normal">
        System is currently in TEST MODE. All incidents created will be test
        incidents.
      </TextWrapper>
    </Container>
  );
};

export default TestModeBanner;
