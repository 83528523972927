import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Text, Switch } from '@rsos/components/capstone/base';
import { setTestMode } from '@rsos/sinatra';
import useIsIRP from '@rsos/utils/useIsIRP';
import { trackTestModeToggle } from './cspNavBarTrackings';

const TestModeControl = () => {
  const dispatch = useDispatch();
  const { irpVersion } = useIsIRP();

  const { sinatra } = useSelector(state => state);
  const [testModeLocal, setTestModeLocal] = useState(sinatra?.ui?.testMode);
  const toggleSwitch = () => {
    trackTestModeToggle(!testModeLocal, irpVersion);
    dispatch(setTestMode(!testModeLocal));
    setTestModeLocal(!testModeLocal);
  };
  return (
    <Box horizontal flow={8} justify="center" align="center">
      <Text size="normal" color="primaryBase">
        Test Mode
      </Text>
      <Switch isOn={testModeLocal} onClick={toggleSwitch} />
    </Box>
  );
};
export default TestModeControl;
