import React from 'react';
import styled from 'styled-components';
import { Box, Absolute } from '@rsos/components/capstone/base';

export const SliderWrapper = styled(Box).attrs({
  bg: 'foreground',
  p: 10,
  align: 'center',
  cursor: 'auto',
})`
  display: grid;
  grid-template-columns: 2.5fr 0.5fr 4fr 0.5fr;
  grid-column-gap: 4px;
`;

export const VolumeSliderTooltip = () => {
  return (
    <Absolute bottom="-21px" left="-10px">
      <StyledTooltip>Mute</StyledTooltip>
    </Absolute>
  );
};

export const StyledTooltip = styled(Box).attrs(() => ({
  width: 35,
  padding: '5px',
  height: 20,
  backgroundColor: 'primaryText',
  color: 'background',
  borderRadius: 5,
}))`
  font-size: 10px;
`;
